import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import classes from "./styles.module.scss";
import clsx from "classnames";
import YoutubeVideo from "@/ui/YoutubeVideo";
import Play from "./Play.svg";

export default function YoutubeVideoModal({ className, variant, video_embed_url, children, ...props }) {
  const [show, setShow] = useState(false);
  const triggerProps = useMemo(
    () => ({
      className: clsx(classes.video__trigger),
      onClick: typeof children === "function" ? undefined : () => setShow(true),
      ...props,
    }),
    [children, props]
  );

  if (!video_embed_url) {
    return <div className={clsx(className)}>{children}</div>;
  }

  return (
    <div className={clsx(className, classes.video, `variant-${variant}`)}>
      <div {...triggerProps}>
        <i className={classes.video__triggerIcon}>
          <Play />
        </i>
        {typeof children === "function" ? children({ show, setShow }) : children}
      </div>
      <Modal show={show} size="xl" className={classes.video__modal} onHide={() => setShow(false)}>
        <YoutubeVideo video_embed_url={video_embed_url} />
      </Modal>
    </div>
  );
}

YoutubeVideoModal.defaultProps = {
  className: "",
  variant: "primary",
  video_embed_url: null,
};

YoutubeVideoModal.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary", "quinary", "senary"]),
  video_embed_url: PropTypes.string,
};
