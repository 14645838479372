import PropTypes from "prop-types";
import clsx from "classnames";
import { getYoutubeVideoEmbedUrl } from "./getYoutubeVideoEmbedUrl";

/**
 *
 * @param className
 * @param video_embed_url
 * @param title
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function YoutubeVideo({ className, video_embed_url, title }) {
  const video = getYoutubeVideoEmbedUrl(video_embed_url);

  if (!video) {
    return null;
  }

  return (
    <div className={clsx(className)}>
      <iframe
        width="1920"
        height="1080"
        src={video}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  );
}

YoutubeVideo.defaultProps = {
  className: "ratio ratio-16x9",
  title: "YouTube video player",
  video_embed_url: null,
};

YoutubeVideo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  video_embed_url: PropTypes.string,
};
