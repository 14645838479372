import { Col, Container, Nav, Row, Tab, Accordion } from "react-bootstrap";
import ArrowRight from "@/assets/ArrowRight.svg";
import { Heading } from "@/ui/Heading";
import { DynamicText } from "@/ui/DynamicText";
import { DynamicHtml } from "@/ui/DynamicHtml";
import { useLocale } from "@/hooks/useLocale";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import imagesMetaData from "../../../.sourceflow/image_metadata.json";

export const TabList = ({ id, title, text, items, variant }) => {
  const breakpoint = useMediaQuery("only screen and (min-width: 1200px)");
  const [isMobile, setMobile] = useState(null);
  const locale = useLocale();

  useEffect(() => {
    setMobile(!breakpoint);
  }, [breakpoint]);

  return (
    <div>
      <Container>
        <Heading className="mb-4" variant={variant === "secondary" ? "primary" : variant}>
          <DynamicText path={`${title.path}.${locale}`} tag="h2" />
        </Heading>
        <DynamicHtml path={`${text.path}.${locale}`} className="pb-3" />
        <div className={clsx(classes.tabList, "p-4 px-md-0 py-md-5 text-overlay")} data-variant={variant}>
          <SourceFlowImage
            className="text-overlay__background"
            path="page.home.reasons.bg"
            src="/assets/banners/default.png"
            size="1480x600"
            alt=""
            imagesMetaData={imagesMetaData}
          />
          <div className="text-overlay__content">
            {isMobile ? (
              items.map((i, k) => (
                <Accordion id={`${id}_${k}`} key={k} className="mx-md-5">
                  <Accordion.Item eventKey={k}>
                    <Accordion.Header>
                      <span>{i.title}</span>
                      <i className="flex-shrink-0">
                        <ArrowRight />
                      </i>
                    </Accordion.Header>
                    <Accordion.Body className="mt-3">{i.content}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            ) : (
              <Tab.Container id={id} defaultActiveKey={0}>
                <Row>
                  <Col xs={12} xl={4} className="px-0">
                    <Nav variant="pills" className={clsx(classes.tabList__nav, "pb-4 pb-xl-0")}>
                      {items.map((i, k) => (
                        <Nav.Link key={k} eventKey={k} className={clsx(classes.tabList__navLink, "px-4 pe-xl-5 py-3")}>
                          <span>{i.title}</span>
                          <i className="flex-shrink-0">
                            <ArrowRight />
                          </i>
                        </Nav.Link>
                      ))}
                    </Nav>
                  </Col>
                  <Col xs={12} xl={8} className="px-0">
                    <Tab.Content className="h-100">
                      {items.map((i, k) => (
                        <Tab.Pane key={k} eventKey={k} className="pe-xl-5 h-100">
                          {i.content}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

TabList.defaultProps = {
  id: "tabList",
  title: { path: "ui.tablist.title" },
  text: { path: "ui.tablist.text" },
  items: [],
  variant: "secondary",
};
