import { getYoutubeIdFromUrl } from "./getYoutubeIdFromUrl";

/**
 *
 * @param video_embed_url
 * @param options
 * @returns {string|null}
 */
export const getYoutubeVideoEmbedUrl = (
  video_embed_url,
  options = { controls: 1, color: "white", rel: 0, autoplay: 1 }
) => {
  if (!video_embed_url) {
    return null;
  }

  const video_id = getYoutubeIdFromUrl(video_embed_url);
  let [_, params] = video_embed_url.split("?");
  params = new URLSearchParams({ ...params, ...options });

  return `https://www.youtube-nocookie.com/embed/${video_id}?${params.toString()}`;
};
