/**
 *
 * @param video_embed_url
 * @returns {*|boolean}
 */
export const getYoutubeIdFromUrl = (video_embed_url) => {
  const match = video_embed_url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);

  return match && match[7].length === 11 ? match[7] : false;
};
